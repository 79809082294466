// Function to get the missing number



export default function getMissingNo(myCurrentNumbers, maxValue) {

    if (myCurrentNumbers.length===0) {
        return 0;
    }

    myCurrentNumbers.sort(function(a, b) {
        return a - b;
    });

    if (myCurrentNumbers[0]>=1) {
        return 0;
    }

    let i = 0;

    while (i < myCurrentNumbers.length) {
        const element = myCurrentNumbers[i];
        var element2 = myCurrentNumbers[i-1]
        let Differenz = element-element2;

        if (Differenz > 1) {
            return element2+1;
        }
        i++;
    }

    if (i < maxValue) {
        return myCurrentNumbers[myCurrentNumbers.length-1]+1
    }
    

    // Wenn kein Platz mehr Vorhanden ist.
    else {
        throw new Error('Es ist kein Platz mehr im Klassenraum, vergrößern Sie den Klassenraum, um weitere Personen hinzuzufügen.');
    }


}


