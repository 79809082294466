import { Button, Divider, Input, message, notification, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { UsergroupAddOutlined } from '@ant-design/icons';
import getMissingNo from '../../functions/getfirstmissingNumber';
import { useDispatch, useSelector } from 'react-redux';
import { addStudent } from '../../features/students/students';




export default function AddMoreStudents() {
    const { TextArea } = Input;
    const { Text } = Typography;
    const [textInput, setTextInput] = useState();
    const dispatch = useDispatch();

    const data = useSelector((state) => state);
    const highestNumber = (data.klassenraum.y * data.klassenraum.x)-1;

    var arrayWithCurrentUsedId = [];

    data.students.forEach(element => {
        arrayWithCurrentUsedId.push(element.SitzplatzId);
    });

    function AddMultipleStudents() {
      
    var NamenArray = textInput.split(/\r?\n/);

    try {
      NamenArray.map((e) => {
        var firstMissingNumber = getMissingNo(arrayWithCurrentUsedId, highestNumber);
        // Add Student to Data and array of Used Ids
        arrayWithCurrentUsedId.push(firstMissingNumber);
        dispatch(addStudent({name: e,SitzplatzId: firstMissingNumber}));
        setTextInput("")
      })
      message.success('Es wurde die Schüler ' + textInput + ' zur Klasse hinzugefügt.');
      }catch (error) {
        notification.error({message: 'Fehler: ' + error.message});
      }
      
    }



    return (
    <>
        <Divider></Divider>
        <Text>Fügen Sie einfach durch Eintragen oder mit Copy & Paste mehrere Schüler hinzu.</Text><br />
        <Text>Jeder Name wird in eine seperate Zeile geschrieben.</Text>
        <TextArea rows={5} placeholder="Schüler 1&#10;Schüler 2&#10;Schüler 3&#10;Schüler 4&#10;Schüler 5&#10;..." value={textInput} onChange={(e) => {setTextInput(e.target.value)}} style={{marginTop: "10px"}}/>
        <Button type="primary" style={{marginTop: "10px"}} icon={<UsergroupAddOutlined />} onClick={(e) => {AddMultipleStudents()}}>Schüler hinzufügen</Button>
    </>
  )
}


