import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import Tabelle from './tabele'

export default function Sitzplan(props) {
  // Gets Data from Redux
  const data = useSelector(state => state)
  return (
    <>
        <Helmet>
        <title>Sitzordung, Sitzplan deiner Klasse - Sitzplan.Digital</title>

      

      <meta name="description" content="Einsehen, wie deine Klasse momentan sitzt" />
      <meta property="og:type" content="table" />
      </Helmet>

        <Tabelle data={data}/>
    </>
  )
}
