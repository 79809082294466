export function aktuellesDatumAlsString() {
    // Erstelle ein Date-Objekt für das aktuelle Datum
    const aktuellesDatum = new Date();
  
    // Definiere Monatsnamen und Wochentage auf Deutsch
    const monatsnamen = [
      "Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
  
    const wochentage = [
      "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"
    ];
  
    // Extrahiere Tag, Monat, Jahr und Wochentag
    const tag = aktuellesDatum.getDate();
    const monat = aktuellesDatum.getMonth();
    const jahr = aktuellesDatum.getFullYear();
    const wochentag = aktuellesDatum.getDay();
  
    // Erstelle den Datumsstring in deutscher Sprache
    const datumString = `${wochentage[wochentag]}, ${tag}. ${monatsnamen[monat]} ${jahr}`;
  
    // Gib das aktuelle Datum in deutscher Sprache zurück
    return datumString;
  }

  