import {Button, message, notification} from 'antd';
import React, {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { changeStudentSitzplatzIdFromOldToNew, shuffleStudents } from '../../features/students/students';
import './tablestyle.css'

import 'drag-drop-touch';



export default function Tabelle(props) {

    useEffect(() => {
        window.DragDropTouch && window.DragDropTouch();
      }, []);

    

    const studentsData = props.data.students;
    const layoutData = props.data.klassenraum;

    const dispatch = useDispatch();


    // Position
    var ElementCurrentlyOver = null;


    function handleDragStart(e) {

        // console.log('Drag Started', e.nativeEvent.target);

    }

    function handleDragEnd(e) {
        const ElementDragend = e.nativeEvent.target;
        
        // Indexes
        const newIndex = ElementCurrentlyOver.getAttribute("number");
        const oldIndex = ElementDragend.id;




        try {
            if (ElementCurrentlyOver.className === 'Tisch'){
                // Tausche zwei Tische
                throw new Error('Hier sitzt schon jemand!');

            }else{

                dispatch(changeStudentSitzplatzIdFromOldToNew({SitzplatzIdNeu: newIndex, SitzplatzIdOld: oldIndex}));
            }

            message.success('Der Tisch wurde von der Position ' + oldIndex + ' zur Position ' + newIndex + ' verschoben.')
        } catch (error) {
            notification.error({message: 'Fehler: ' + error.message});
        }
    }


    function handleDragOverField(e) {
        ElementCurrentlyOver = e.nativeEvent.target;
        e.preventDefault();
    }


    // Funktion um zu schauen, ob es einen Studenten bei einer bestimmten Nummer sitzt
    function TestIfStudent(seatNumber) {

        const result = studentsData.filter((element) => element.SitzplatzId === seatNumber)

        
        if (result[0] != null) {
            return {isCurrentlyStudentOrNot: true, displayname: result[0].name} 
        }
        if (result[1] != null){
            throw new Error('Oh, es scheint als ob die Tabelle nicht Richtig gerendert wurde.')
        }

        return {isCurrentlyStudentOrNot: false}
    }

    const CustomTabelle = () => {
        return <tbody>{[...Array(layoutData.y)].map((e, yn) => {
          return <tr key={yn} id={yn}>
            {
                [...Array(layoutData.x)].map((e, xn) => {
                    var CurrentField = yn*layoutData.x+xn; // Calculate the current Field Number

                    // test if in this Feld is an Item
                    var hasSeatOnLoad = TestIfStudent(CurrentField);

                    return <td id={xn+yn} x={xn} y={yn} key={yn + xn}  onDragOver={handleDragOverField} number={CurrentField} className="Feld">
                        {
                            // Erstellen der Studenten Tische mit einer div, wenn ein Student dort ist.
                            hasSeatOnLoad.isCurrentlyStudentOrNot ? <div key={hasSeatOnLoad.displayname} className="Tisch" id={CurrentField} draggable="true" onDragStart={handleDragStart} onDragEnd={handleDragEnd}>{hasSeatOnLoad.displayname}</div> : <></>                              
                        }
                    </td>
                })
            }
          </tr>
        })}</tbody>
      }


      // funktion die die action von Redux ausführt um die Schüler zufällig anzuordnen
        function ransomSitzplatz() {
            dispatch(shuffleStudents());
        }


  return (

      <>
          <div style={{marginTop: '20px', marginBottom: '20px', width: '100%', display: 'flex', justifyContent: 'end'}}>
            <Button onClick={() => ransomSitzplatz()} type={'primary'}>Schüler zufällig umsetzen</Button>
          </div>

          <table>
              <CustomTabelle/>
          </table>
      </>
  )
}
