import React from 'react'
import { Modal, Form, Input, Button, notification, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import getMissingNo from '../functions/getfirstmissingNumber';

import { addStudent } from '../features/students/students.js'



export default function AddStudentPopup(props) {
    const dispatch = useDispatch();
    const data = useSelector((state) => state);

    const dummyData = {
        name: "Max Maier",
    }
    const [form] = Form.useForm();

    const handleSubmit = (values) => {


        // getting the first seat Between the numbers 0 and the Highest Number :  (layout.y*layout.x)-1

        const highestNumber = (data.klassenraum.y * data.klassenraum.x)-1;
        var arrayWithCurrentUsedId = [];

        data.students.forEach(element => {
            arrayWithCurrentUsedId.push(element.SitzplatzId);
        });
        console.log(highestNumber);



        try {
            var firstMissingNumber = getMissingNo(arrayWithCurrentUsedId, highestNumber);

        dispatch(addStudent({name: values.name,SitzplatzId: firstMissingNumber}));
        props.PopupOpen(false);
        // clean form
        handleCancel();
        message.success('Es wurde der Schüler ' + values.name + ' mit auf dem Sitzplatz ' + firstMissingNumber + ' hinzugefügt.')

        } catch (error) {
            notification.error({message: 'Fehler: ' + error.message});
        }
        
    }

    const handleCancel = () => {
        form.resetFields();
        props.PopupOpen(false);
    };


  return (
    <div>
        <Modal
            title={"Neuen Schüler hinzufügen:"}
            centered
            open={props.open}
            onCancel={() => handleCancel()}
            footer={[
                <Button form="changeData" key="submit" htmlType="submit">
                    Speichern
                </Button>
                ]}
        >
            <Form
            form={form}
            name="changeData"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            onFinish={handleSubmit}
            >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Bitte geben sie einen Namen für den Schüler ein.' }]}
            >
                <Input placeholder={dummyData.name}/>
            </Form.Item>
            </Form>
        </Modal>
    </div>
  )
}
