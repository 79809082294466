import React from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Space, notification, message, Typography, Divider} from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react';
import Popup from './Popup'
import Helmet from 'react-helmet';
import showConfirmModal from '../../functions/confirmModal';
import { deleteStudentBySitzplatzId, updateStudentById } from '../../features/students/students.js'
import AddMoreStudents from '../addmultiplestudents';




export default function Schueler(props) {


    const { Text, Title } = Typography;



    // get Data from Redux Store
    const students = useSelector((state) => state.students);
    const dispatch = useDispatch();
    

    
    const [modal2Visible, setModal2Visible] = useState();
    const [editModalData, setEditModalData] = useState({});

    function updateData(newUserData) {
        // Gives the new Schuelerdata to the Function who updates it everywhere (changeSchuelerData(allSchuelerdata))
        
        try {
            // Send Data Up
            dispatch(updateStudentById({name: newUserData.name, SitzplatzId: editModalData.SitzplatzId}));
            // URL Must be build
            message.success('Name wurde Erfolgreich zu ' + newUserData.name + ' verändert.');

        } catch (error) {
            notification.error({message: 'Fehler: ' + error.message});
        }
    }


    
    function handleDeleteUserById(SitzplatzIdToDelete) {
        var indexInOriginalArray = students.findIndex((element) => element.SitzplatzId === SitzplatzIdToDelete);
        showConfirmModal("Wollen Sie " + students[indexInOriginalArray].name + " wirklich löschen?", "")
            .then((result) => {

                try {
                    dispatch(deleteStudentBySitzplatzId({SitzplatzId: SitzplatzIdToDelete}));
                    message.success('Es wurde der Benutzer '+ students[indexInOriginalArray].name + ' Erfolgreich gelöscht.');
                } catch (error) {
                    notification.error({message: 'Error' + error.message});
                }
            })
            .catch((err) => {
                notification.error({message: 'Fehler: ' + err.message});
            });
    }

    

    function getSingleStudentDataBySitzplatzId(SitzplatzId) {
        var filtered = [];
        for (let i = 0; i < students.length; i++) {
            if (students[i].SitzplatzId === SitzplatzId) {
                filtered.push(students[i]);
            }
        }
        if (filtered.length > 1) {
            throw new Error('Daten von ' + SitzplatzId +'nicht Valide!');
        } else {
            return filtered[0];
        }
    }

    function openEditModalById(SitzplatzIdToOpen) {
        // Open Edit Modal with Id
        // Get Information about Student with Specific SitzplatzId
        setEditModalData(getSingleStudentDataBySitzplatzId(SitzplatzIdToOpen));
        setModal2Visible(true);

    }


    // Table Columns
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text, row) => <Text  type="primary" strong onClick={(e) => { openEditModalById(row.SitzplatzId)}}>{text}</Text>,
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: 'Sitzplatz Id',
          dataIndex: 'SitzplatzId',
          key: 'SitzplatzId',
          sorter: (a, b) => a.SitzplatzId - b.SitzplatzId,
        },
        {
          title: 'Aktionen',
          key: 'action',
          render: (text, row) => (
            <Space size="middle">
            <DeleteOutlined style={{color: 'red'}} onClick={(e) => { handleDeleteUserById(row.SitzplatzId)}}/>
            <EditOutlined style={{color: 'green'}} onClick={(e) => { openEditModalById(row.SitzplatzId)}} />             
            </Space>
          ),
        },
      ];


    //   Das Array Schuelerdata sieht so aus:
    //   const data = [
    //     {
    //       name: 'John Brown',
    //       SitzplatzId: 32,
    //     },
    //     {
    //       name: 'Jim Green',
    //       SitzplatzId: 42,
    //     },
    //     {
    //       name: 'Joe Black',
    //       SitzplatzId: 10,
    //     },
    //   ];
    

  return (
    <>
    <Helmet>
      <title>Schüler Liste - Sitzplan.Digital</title>

      <meta name="description" content="Hier kann man mehrere Schüler hinzufügen und auch einsehen, welche Schüler momentan in der Klasse sind." />
      <meta property="og:type" content="table" />
    </Helmet>
    <AddMoreStudents/>
    <Divider></Divider>
    <Title level={5} strong>Schüler-Liste:</Title>
    <Table columns={columns} size="large" dataSource={students} pagination={{ defaultPageSize: 25}}/>
    <Popup open={modal2Visible} update={updateData} data={editModalData} openclose={setModal2Visible}/>
    </>
  )
}


