import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    x: 12,
    y: 9
}


export const klassenraumSlice = createSlice({
    name: 'klassenraum',
    initialState,
    reducers: {
        changeWidth: (state, action) =>{
            state.x = state.x+action.payload.difference
        },
        updateAllKlassenraumData: (state, action) => {
            state.x = action.payload.x;
            state.y = action.payload.y;
        }
    }
})


export const { changeWidth, updateAllKlassenraumData } = klassenraumSlice.actions;

export default klassenraumSlice.reducer;