import { Button, QRCode, Space, Typography, message } from 'antd'
import Modal from 'antd/es/modal/Modal'
import Link from 'antd/es/typography/Link'


const { Title} = Typography;


export default function ShareModal(props) {

  
  return (
    <>
    <Modal
        title="Teile oder speichere deinen Sitzplan:"
        centered
        open={props.shareModalOpen}
        onCancel={() => {props.setShareModalOpen(false)}}
        footer={[
            <Button key="submit" onClick={() => {props.setShareModalOpen(false)}}>
              Schließen
            </Button>,
            <Button key="back"  type="primary" onClick={() => {navigator.clipboard.writeText(props.linkQRCode);message.info('Der Link wurde in dein Clipboard Kopiert.');props.setShareModalOpen(false);}}>
            Link Kopieren
            </Button>,
          ]}
      >
        <p>Da SitzplanDigital.de den Sitzplan nicht auf einem Server speichert, ist es nötig die folgende URL zu selbst zu speichern oder zu teilen.</p>
        <p>Die URL kann auch ganz einfach über den abgebildeten QR-Code an Mobilgeräte geteilt werden.</p>
        <p><span style={{fontWeight:"bold"}}>Vorsicht:</span> Nach jeder Änderung des Sitzplans, ändert sich auch die URL, die dann einfach erneut gespeichert werden kann.</p>
        <Title level={4}>QR-Code und Link:</Title>
        <Space style={{justifyContent: "space-between", width: "100%", marginBottom: "20px"}}>
            <QRCode value={props.linkQRCode} style={{right: "0px"}}/>    
            <Link href={props.linkQRCode}>{}</Link>      
        </Space>
      </Modal>
    </>
  )
}
