import { createBrowserRouter } from 'react-router-dom';
import HomepageComponent from '../components/homepage';
import SchuelerComponent from '../components/schulerpage/index.js';
import Root from '../components/root/index'
import { Typography } from 'antd';
import { Routes } from './routes';
import store from '../store.js';
import Sitzplan from '../components/sitzordnung';
import ArbeitsgruppenView from '../components/arbeitsgruppen';
import ZufallsgeneratorView from '../components/zufallsgeneratorview';
import ExportToPDF from '../components/exporttopdf';
import { updateAllStudentsData } from '../features/students/students';
import { updateAllKlassenraumData } from '../features/klassenraum/klassenraum';
import About from '../components/about';
import ErrorNOTFound from '../components/404';

const { Title } = Typography;

function handleStudentDataChange(newStudentArrayData) { // Updatet eine Veränderung an Studenten Daten (UserDaten wie Klassenraumgröße bleiben gleich)
    // Wir bekommen hier ein neues Schuler Array zurück
    console.log(newStudentArrayData);
    store.dispatch(updateAllStudentsData(newStudentArrayData));
  }



const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorNOTFound/>,
    children: [
      {
        path: "/",
        element: <div style={{overflowY: 'scroll', height: '88vh'}} className="scrollbar-hidden"><HomepageComponent/></div>,
      },
      {
        path: Routes.SchulerView,
        element: <div style={{overflowY: 'scroll', height: '88vh'}} className="scrollbar-hidden"><Title level={2}>Schüler:</Title><SchuelerComponent schueler={store.getState().students} changeSchuelerData={handleStudentDataChange}/></div>
      },
      {
        path: Routes.SitzplanView,
        element: <div style={{height: '100%'}}><Title level={2}>Sitzplan:</Title><Sitzplan userData={store.getState().students} changeSchuelerData={handleStudentDataChange}/></div>
      },
      {
        path: Routes.GruppenErstellerView,
        element: <div style={{overflowY: 'scroll', height: '88vh'}} className="scrollbar-hidden"><Title level={2}>Arbeitsgruppen erstellen:</Title><ArbeitsgruppenView/></div>
      },
      {
        path: Routes.ZufallsgeneratorView,
        element: <div style={{overflowY: 'scroll', height: '88vh'}} className="scrollbar-hidden"><Title level={2}>Zufallsgenerator:</Title><ZufallsgeneratorView userData={store.getState().students}/></div>
      },
      {
        path: Routes.ExportierenView,
        element: <div style={{overflowY: 'scroll', height: '88vh'}} className="scrollbar-hidden"><Title level={2}>Sitzplan exportieren:</Title><ExportToPDF/></div>
      },
      {
        path: Routes.AboutView,
        element: <div style={{overflowY: 'scroll', height: '88vh'}} className="scrollbar-hidden"><Title level={2}>Über uns:</Title><About/></div>
      }
    ],
  },
]);

export default router;