import { PDFDocument, rgb, PageSizes, drawSvgPath } from "pdf-lib";
import { generateQRCode } from "./generateQRCode";
import { aktuellesDatumAlsString } from "./dateasstring";

async function generatePDF(studentData, layoutData, LinkToData, Title, Version) {
  const qrCodeLink = LinkToData; // Der QR-Code-Link
  const descriptionTextforQRCode = "Besuchen unter: " + LinkToData;
  const qrCodeSize = 60; // Größe des QR-Codes

  // Ihr SVG-Code als Zeichenkette
  const qrCodeArray = await generateQRCode(qrCodeLink, qrCodeSize); // Ersetzen Sie dies durch Ihren SVG-Code

  // Lade das Logo-Bild clientseitig
  const logoResponse = await fetch("https://sitzplandigital.de/icon-128x128.png"); //to do change to sitzplandigital.de
  if (!logoResponse.ok) {
    throw new Error(`Failed to fetch logo: ${logoResponse.statusText}`);
  }
  const logoImageArrayBuffer = await logoResponse.arrayBuffer();

  // Wandele das ArrayBuffer in ein Uint8Array um
  const ImageLogo = new Uint8Array(logoImageArrayBuffer);

  const pdfDoc = await PDFDocument.create();
  const logoImage = await pdfDoc.embedPng(ImageLogo);
  const qrCodeImage = await pdfDoc.embedPng(qrCodeArray);

  const page = pdfDoc.addPage([PageSizes.A4[1], PageSizes.A4[0]]); // Seitenbreite und -höhe umkehren

  // All Variables for the Table 
  const { x, y } = layoutData;
  const cellWidth = (PageSizes.A4[1] - 40) / x; // 40 for margins
  const cellHeight = (PageSizes.A4[0] - 70) / y; // 70 for margins - 20 unten 50 oben 
  const backgroundColorTable = rgb(0.88235294117, 0.98039215686, 0.92156862745)
  const borderColor = rgb(0.39607843137, 0.83921568627, 0.5725490196)

  const helveticaFont = await pdfDoc.embedFont("Helvetica");


  const fontSize = 12;

  const drawHeader = () => {
    const PrimaryColor = rgb(0, 0.72549019607, 0.41960784313);
    const lightGrey = rgb(0.75294117647, 0.75294117647, 0.75294117647);

    // Logo
    const logoX = 20; // X-Position für das Logo
    const logoY = PageSizes.A4[0] - 65; // Y-Position für das Logo
    const logoWidth = 50; // Breite des Logos
    const logoHeight = 50; // Höhe des Logos

    // Branding Text
    const textX = 75; // X-Position für den Text
    const textY = PageSizes.A4[0] - 59; // Y-Position für den Text
    const textbranding = "Erstellt mit SitzplanDigital.de | Für Zufallsgenerator / Nachbearbeitung und Gruppenerstellung QR Code Scannen"; // Textinhalt

    // SitzplanVersion Text
    const versiontextX = 15; // X-Position für den Text
    const versiontextY = 15; // Y-Position für den Text
    const textVersion = "Klassen Sitzplan Version: " +  Version + " | Datum: " + aktuellesDatumAlsString(); // Textinhalt

    // Class Name Text
    const ClassNametextX = 75;
    const ClassNametextY = PageSizes.A4[0] - 45;
    const ClassName = Title;

    // QR-Code
    const qrCodeX = PageSizes.A4[1] - 100; // X-Position für den QR-Code
    const qrCodeY = PageSizes.A4[0] - 70; // Y-Position für den QR-Code

    // Linie
    const lineY = PageSizes.A4[0] - 90; // Y-Position für die Linie

    // Zeichne das Logo
    page.drawImage(logoImage, {
      x: logoX,
      y: logoY,
      width: logoWidth,
      height: logoHeight
    });

    // Zeichne den Text
    page.drawText(textVersion, {
      x: versiontextX,
      y: versiontextY,
      size: 8,
      font: helveticaFont,
      color: lightGrey
    });

    // Zeichne den Text
    page.drawText(ClassName, {
        x: ClassNametextX,
        y: ClassNametextY,
        size: 15,
        font: helveticaFont,
        color: PrimaryColor
      });

    // branding:
    page.drawText(textbranding, {
      x: textX,
      y: textY,
      size: 8,
      font: helveticaFont,
      color: lightGrey
    });

    // Zeichne den QR-Code

    // Add the QR code image to the page
    page.drawImage(qrCodeImage, {  //
      x: qrCodeX, // Adjust the X position
      y: qrCodeY, // Adjust the Y position
      width: qrCodeSize, // Adjust the size if needed
      height: qrCodeSize
    });

    // Zeichne den Text für den QR Code
    page.drawText(descriptionTextforQRCode, {
      x: textX,
      y: qrCodeY - 5,
      size: 5,
      font: helveticaFont,
      color: lightGrey
    });

    // Zeichne die Linie
    page.drawLine({
      start: { x: 20, y: lineY },
      end: { x: PageSizes.A4[1] - 20, y: lineY },
      thickness: 1,
      color: lightGrey
    });
  };

  const drawTable = () => {
    try {
      for (let row = 0; row < y; row++) {
        for (let col = 0; col < x; col++) {
          const cellX = col * cellWidth + 20; // 20 for left margin
          const cellY = PageSizes.A4[0] - (row + 1) * cellHeight - 50 ; // - 50 for top margin
          const cellText = studentData.find(
            (student) => student.SitzplatzId === row * x + col
          )?.name;

          if (cellText) {
            if (cellX >= 0 && cellY >= 0 && cellWidth > 0 && cellHeight > 0) {
              const textWidth = helveticaFont.widthOfTextAtSize(
                cellText,
                8
              );
              const textX = cellX + (cellWidth - textWidth) / 2; // Zentriert den Text horizontal
              const textY = cellY - cellHeight / 4 ; // Zentriert den Text vertikal

              page.drawRectangle({
                x: cellX,
                y: cellY - cellHeight / 2,
                width: cellWidth,
                height: cellHeight / 2,
                color: backgroundColorTable,
                border: 2,
                borderColor: borderColor,
              });

              page.drawText(cellText, {
                x: textX, // 5 for padding
                y: textY, // 5 for padding
                size: 8,
                font: helveticaFont,
                color: rgb(0, 0, 0)
              });
            }
          }
        }
      }
    } catch (error) {
      console.error("Fehler beim Zeichnen der Tabelle:", error);
    }
  };

  // Zeichne den Header
  drawHeader();

  // Zeichne die Tabelle
  drawTable();

  const pdfBytes = await pdfDoc.save();

  return pdfBytes;
}

export { generatePDF };
