import './App.css';
import { Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import UpdateURL, { EncodeURLEnding } from './functions/URLHandler';


// React Redux
import store from './store.js';
import { updateAllStudentsData } from './features/students/students';
import { updateAllKlassenraumData } from './features/klassenraum/klassenraum';

import {
  RouterProvider,
} from "react-router-dom";

// Router
import router from './router/index';






const { Sider, Content } = Layout;


// Always updates URL to Store Information's
store.subscribe(() => {
  // console.log("Store has updated", store.getState(), "updating URL now...");
  try {
    UpdateURL(store.getState());
  } catch (error) {
    console.log(error.message);
  }
})

function App() {

  const [collapsed, setCollapsed] = useState(false);
  const [openMenu, setOpenMenu] = useState('1');
  const [OpenAddUserMenu, setOpenAddUserMenu] = useState(false);

  // Modal for Sharing
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [linkQRCode, setLinkQRCode] = useState('https://vonreyher.com/kontakt');



  // get USER Data on first Load
  useEffect(() => {
    getURLdata();
  }, [])

  // const dispatch = useDispatch();


  // get USER Data after Reload of Page
  async function getURLdata() {
    const urlParams = await window.location.search;
    const newObject = await EncodeURLEnding(urlParams.substring(1))
    // Hier müssen alle Daten aus der URL Dispatched werden.
    // 1) Dispatch USER DATA
    store.dispatch(updateAllStudentsData(newObject.students))
    // 2) Dispatch Klassenraum DATA
    store.dispatch(updateAllKlassenraumData(newObject.klassenraum))
  }



  // Funktion to open the Popup to add a new User
  function handleNewStudent() {
    setOpenAddUserMenu(true)
  }
  

  // Funktion, wenn das Menu gedrückt wird
  function handleMenuClick(e) {
    var parameter = window.location.search.slice(1);
    console.log(parameter);
    // let menuNumber = e.key; // Achtung Fehler mit den Untermenü
    // setOpenMenu(menuNumber)
    router.navigate(e.key, { replace: true });
  }








  return (
  <RouterProvider router={router} />
  );
}

export default App;
