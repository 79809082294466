import React from 'react';
import { Button, Result } from 'antd';
import Link from 'antd/es/typography/Link';
import { Helmet } from 'react-helmet';


  


export default function ErrorNOTFound() {
  return (
    <>
    <Helmet>
        <title>Fehler - 404 - Nicht gefunden</title>
    </Helmet>
        <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
    />
    </>
  )
}
