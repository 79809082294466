const QRCode = require("qrcode");

export async function generateQRCode(data, size) {
  try {
    const qrCodeDataURL = await QRCode.toDataURL(data, {
      width: 300,
      errorCorrectionLevel: "H" // Adjust the error correction level as needed
    });

    const base64Image = qrCodeDataURL.split(",")[1]; // Extract the base64 data

    const decodedImage = atob(base64Image);
    const qrCodeArray = new Uint8Array(decodedImage.length);

    for (let i = 0; i < decodedImage.length; i++) {
      qrCodeArray[i] = decodedImage.charCodeAt(i);
    }

    return qrCodeArray;
  } catch (error) {
    console.error("Fehler beim Generieren des QR-Codes:", error);
    return "";
  }
}

